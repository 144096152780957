.marker
  position: absolute
  width: 56px
  height: 109px
  @media screen and (max-width: 769px)
    width: 27px
    height: 45px
.one
  top: 21%
  left: 24%
  @media screen and (max-width: 1800px)
    top: 15%
  @media screen and (max-width: 1440px)
    top: 10%
  @media screen and (max-width: 769px)
    top: 8%
    left: 18%
  @media screen and (max-width: 630px)
    top: 7%
  @media screen and (max-width: 425px)
    top: 2%
    left: 20%
.two
  top: 46%
  left: 33.5%
  @media screen and (max-width: 1800px)
    top: 43%
  @media screen and (max-width: 1440px)
    top: 31%
  @media screen and (max-width: 769px)
    top: 25%
    left: 29%
  @media screen and (max-width: 700px)
    top: 21%
  @media screen and (max-width: 630px)
    top: 19%
  @media screen and (max-width: 425px)
    top: 15%
    left: 30.5%
  @media screen and (max-width: 375px)
    left: 28%

.three
  top: 9%
  left: 51.5%
  @media screen and (max-width: 1800px)
    top: 5%
  @media screen and (max-width: 1440px)
    top: 2%
  @media screen and (max-width: 425px)
    top: -0.5%
.four
  top: 6%
  left: 54.7%
  @media screen and (max-width: 1800px)
    top: 3%
  @media screen and (max-width: 1440px)
    top: 0%
    left: 55.5%
  @media screen and (max-width: 425px)
    top: -1.5%
    left: 59%
.five
  top: 8%
  left: 58.5%
  @media screen and (max-width: 1440px)
    top: 5%
    left: 59%
  @media screen and (max-width: 769px)
    left: 62%
  @media screen and (max-width: 425px)
    top: 0%
    left: 65.5%
.six
  top: 12%
  left: 67.5%
  @media screen and (max-width: 1800px)
    top: 10%
  @media screen and (max-width: 769px)
    top: 6%
    left: 68%
  @media screen and (max-width: 425px)
    top: 4%
    left: 74%
.seven
  top: 22%
  left: 79%
  @media screen and (max-width: 1800px)
    top: 15%
    left: 74%
  @media screen and (max-width: 769px)
    top: 10%
    left: 75%
  @media screen and (max-width: 630px)
    top: 5%
    left: 76%
  @media screen and (max-width: 425px)
    top: 20%
    left: 88%
  @media screen and (max-width: 375px)
    top: 18%

.eight
  top: 62%
  left: 88%
  @media screen and (max-width: 1800px)
    top: 55%
    left: 87%
  @media screen and (max-width: 1440px)
    top: 44%
    left: 88%
  @media screen and (max-width: 769px)
    top: 24%
  @media screen and (max-width: 630px)
    top: 22%
  @media screen and (max-width: 600px)
    top: 20%
    left: 92%
  @media screen and (max-width: 600px)
    top: 18%
  @media screen and (max-width: 510px)
    top: 16.5%
  @media screen and (max-width: 425px)
    display: none

