.collapseItem
  position: relative
  width: 100%
  height: 90px
  background: #F5F9FB
  border-radius: 50px
  overflow: hidden
  margin: 0 0 30px
  box-shadow: 0px 0px 22px rgba(81, 84, 90, 0.11)
  transition: .3s
  cursor: pointer
  &:hover
    box-shadow: 0px 0px 22px rgba(81, 84, 90, 0.3)
  &_active
    box-shadow: 0px 0px 22px rgba(81, 84, 90, 0.3)
    height: 230px
    @media screen and (max-width: 426px)
      height: 262px

.heading
  padding: 32px 67px
  border-radius: 50px
  position: absolute
  top: 0
  left: 0
  right: 0
  height: 92px
  background: #fff
  display: flex
  justify-content: space-between
  align-items: center
  @media screen and (max-width: 426px)
    padding: 18px 24px

.title
  margin: 0
  font-weight: bold
  font-size: 24px
  line-height: 120%
  color: #030028
  @media screen and (max-width: 426px)
    font-size: 14px
    line-height: 17px
    max-width: 90%

.text
  padding: 102px 67px 28px
  p
    margin: 0 0 20px
    font-weight: 500
    font-size: 16px
    line-height: 23px
    color: #030028
    opacity: 0.7
    &:last-child
      margin: 0
  @media screen and (max-width: 426px)
    padding: 103px 24px 40px
    p
      font-size: 14px
      line-height: 17px

.closeIcon
  width: 25px
  height: 25px
  background: url("../../images/icon/close-collapse.png") no-repeat
  background-size: contain
  transition: .3s
  &_active
    transform: rotate(45deg)
  @media screen and (max-width: 426px)
    width: 14px
    height: 14px