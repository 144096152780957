.title
  font-weight: bold
  font-size: 48px
  line-height: 62px
  text-align: center
  letter-spacing: 0.02em
  @media screen and (max-width: 611px)
    margin: 40px 0 40px!important
  @media screen and (max-width: 426px)
    font-size: 28px
    line-height: 30px
    margin: 0 0 40px!important