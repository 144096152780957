.wrapper
  position: relative
  width: 48.5%
  &::after
    content: url("../../../images/icon/arrow-select.png")
    width: 12px
    height: 6px
    position: absolute
    top: calc( 50% - 10px )
    right: 20px
    color: #030028
.select
  position: relative
  padding: 9px 29px
  width: 100%
  height: 48px
  background: transparent
  border: 2px solid #FFFFFF
  box-sizing: border-box
  filter: drop-shadow(0px 3.42735px 51.4103px rgba(20, 0, 79, 0.04))
  border-radius: 10px
  outline: none
  appearance: none
  font-weight: 500
  font-size: 18px
  color: #fff

  option
    height: 50px
    padding: 9px
    color: #030028
    //background: #000