.section
  padding: 0 0 238px
  @media screen and (max-width: 425px)
    padding: 0 0 70px

.wrapper
  margin: 180px auto 0
  width: 84.5%
  height: 742px
  background: url("../../../images/map-servers/map.png") no-repeat
  background-size: contain
  display: flex
  justify-content: flex-end
  align-items: flex-end
  @media screen and (max-width: 769px)
    width: 100%
  @media screen and (max-width: 425px)
    width: 100%
    height: 450px
    margin: 70px auto 0
  
.content
  width: 55%
  height: 57%
  @media screen and (max-width: 769px)
    margin: 0 auto
    width: 95%
  @media screen and (max-width: 425px)
    height: 100%
    transform: translateY(140px)
  @media screen and (max-width: 375px)
    transform: translateY(120px)

.title
  margin: 0 0 50px
  font-weight: bold
  font-size: 48px
  line-height: 50px
  color: #030028
  @media screen and (max-width: 425px)
    width: 80%
    margin: 0 auto 26px
    text-align: center
    font-size: 28px
    line-height: 30px

.text
