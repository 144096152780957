.cardSmall
  padding: 32px
  background: #fff
  box-shadow: 0px 4px 60px rgba(20, 0, 79, 0.08)
  border-radius: 37px
  display: flex
  justify-content: space-between
  @media screen and (max-width: 1441px)
    padding: 26px
  @media screen and (max-width: 1024px)
    padding: 30px 24px 30px 24px
  @media screen and (max-width: 768px)
    width: 100%
    grid-column: 1 / 6!important

.colRight

.numContainer
  margin: 0 22px 0 0
  @media screen and (max-width: 426px)
    margin: 0 10px 0 0
    display: flex
    flex-direction: column
    justify-content: center

.texts
  //margin: 12px 0 0
  margin: 0
  display: flex
  flex-direction: column
  p
    //opacity: 0.7
    color: #030028a6
    strong
      color: #030028
      opacity: 1!important
    //span
    //  font-weight: bold
    //  color: #030028
    //  opacity: 1 !important

.text
  //margin: 0 0 0 46px
  margin: 0
  font-weight: 500
  font-size: 16px
  line-height: 23px
  color: #030028
  //opacity: 0.7
  @media screen and (max-width: 769px)
    font-size: 14px!important
    line-height: 17px