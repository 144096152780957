.header
  height: 168px
  @media screen and (max-width: 768px)
    height: 100%

.topWrapper
  margin-bottom: 10px
  padding: 70px 160px 0
  display: flex
  justify-content: space-between
  align-items: center
  @media screen and (max-width: 1780px)
    padding: 70px 50px 0
  @media screen and (max-width: 1440px)
    padding: 70px 30px 0
  @media screen and (max-width: 768px)
    padding: 29px 25px 0

.bottomWrapper
  padding: 0 160px
  display: flex
  justify-content: flex-end
  align-items: center
  @media screen and (max-width: 1780px)
    padding: 0 50px
  @media screen and (max-width: 1440px)
    padding: 0 30px
  @media screen and (max-width: 1400px)
    display: none
    //padding: 0 25px
.buttons
  width: 382px
  display: flex
  justify-content: flex-end
  align-items: center
  button:last-child
    margin: 0 0 0 16px
  @media screen and (max-width: 1400px)
    display: none

.burgerContainer
  position: relative
  z-index: 6
  width: 40px
  height: 40px
  display: none
  flex-direction: column
  justify-content: space-evenly
  align-items: center
  transition: .3s
  @media screen and (max-width: 1400px)
    display: flex
  div
    transition: .3s
    width: 30px
    height: 3px
    border-radius: 10px
    background: #fff
  &_active
    position: fixed
    top: 32px
    right: 16px
    div:first-child
      transform: rotate(45deg)
      transform-origin: left
    div:nth-child(2)
      transition: .3s
      opacity: 0
    div:last-child
      transform: rotate(-45deg)
      transform-origin: left
