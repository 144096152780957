.input
  padding: 9px 29px
  margin: 0 0 16px
  width: 100%
  height: 48px
  background: #fff
  box-shadow: 0px 3.42735px 51.4103px rgba(20, 0, 79, 0.04)
  border-radius: 10px
  border: none
  outline: none
  color: #030028
  font-size: 18px
  transition: .3s
  &:focus
    box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.25)
  //line-height: 30px
  &:last-child
    margin: 0
  &::placeholder
    color: rgba(3, 0, 40, 0.4)

.label
  margin: 0 0 6px
  position: relative
  &:last-child
    margin: 0
.labelText
  margin: 0 0 6px
  font-size: 12px
  //line-height: 30px
  color: #FFFFFF

.iconContainer
  position: absolute
  top: calc( 50% - 9px )
  right: 20px
  width: 40px
  height: 22px
  display: flex
  justify-content: flex-end
  align-items: center
  background: #fff
  z-index: 5
  cursor: pointer
  &_withoutLabel
    top: calc( 50% - 15px )