.menuContainer
  padding: 37px 21px
  position: fixed
  top: -110%
  left: 0
  width: 100vw
  height: 100vh
  z-index: 5
  background: linear-gradient(107.33deg, #0094E7 20.64%, #31ACD5 90.51%)
  visibility: hidden
  opacity: 0
  transition: .3s
  &_active
    top: 0
    visibility: visible
    opacity: 1

.language
  display: flex
  align-items: flex-start

.wrapper
  //height: 65%
  height: calc( 100% - 130px )
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  margin: 60px 0 0

.nav
  width: 100%

.buttons
  height: 105px
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  margin-bottom: 90px