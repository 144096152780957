.footer
  padding: 0 0 178px
  min-height: 300px
  max-width: 100vw
  background: #030028
  color: #fff
  @media screen and (max-width: 1266px)
    padding: 0 0 78px

.langSwitch
  display: flex
  justify-content: flex-end
  margin: 40px 0 0
  width: 100%
  text-align: right

.contactsAndLogo
  width: 95%
  display: flex
  justify-content: space-between
  align-items: flex-end
  //@media screen and (max-width: 425px)
  @media screen and (max-width: 1266px)
    margin: 20px auto
    flex-direction: column
    align-items: center
    height: 220px

.contact
  margin: 0
  font-weight: normal
  font-size: 18px
  line-height: 30px
  span
    font-weight: 700

.navAndContactButton
  max-width: 100%
  margin: 65px 0 0
  display: flex
  justify-content: space-between
  align-items: center
  //@media screen and (max-width: 426px)
  @media screen and (max-width: 1266px)
    height: 305px
    margin: 70px auto 20px
    flex-direction: column-reverse
    align-items: center

.navWrap
  width: 75%

.copyrightAndPolicy
  margin: 50px 0 0
  display: flex
  justify-content: space-between
  align-items: flex-start
  font-weight: 500
  font-size: 12px
  line-height: 16px
  color: #FFFFFF
  opacity: 0.8
  //@media screen and (max-width: 425px)
  @media screen and (max-width: 1266px)
    flex-direction: column-reverse
    align-items: center
    text-align: center
    margin: 40px auto 0
    height: 184px

.copyright
  margin: 0
  @media screen and (max-width: 425px)
    width: 80%

.listItem
  margin: 0 0 10px
  &:last-child
    margin: 0

.link
  text-decoration: none
  color: #FFFFFF
  opacity: 0.8

.robowebLinkContainer
  display: flex
  align-items: center
  p
    margin: 0
    font-size: 16px
    line-height: 16px
  a
    text-decoration: none
    margin: 0 0 0 4px
    color: #035EFC
    font-weight: bold
    font-size: 16px
    line-height: 16px
    display: flex
    align-items: center
    width: 250px
    @media screen and (max-width: 426px)
    width: 170px
  span
    color: #fff

  img
    transform: translateY(-2px)
    margin: 0 0 0 6px
    width: 200px
    height: 27px
    object-fit: contain
    @media screen and (max-width: 426px)
      transform: translateY(-3px)
      width: 140px

.getShop
  position: relative
  p
    margin: 0
    max-width: 350px
    font-weight: 500
    font-size: 16px
    line-height: 23px
  img
    position: absolute
    top: 32px
    left: 40%