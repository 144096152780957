.button
  padding: 0 30px
  display: flex
  justify-content: center
  align-items: center
  height: 48px
  outline: none
  appearance: none
  border: 2px solid #fff
  border-radius: 10px
  background: transparent
  color: #fff
  font-weight: 800
  font-size: 18px
  line-height: 24px
  white-space: nowrap
  cursor: pointer
  transition: .3s
  &:hover
    box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1)
    opacity: .8
  &:active
    transform: scale(0.97)
  &_footer
    background: #fff
    color: #030028

.button_white
  background: #fff
  color: #78C2DD

.button_cyan
  background: #78C2DD
  color: #fff
  border: 1px solid #78C2DD
  @media screen and (max-width: 425px)
    margin: 0 auto

.button_cyan_outline
  background: #78C2DD
  color: #fff
  border: none

.showMoreButton
  width: 170px
  margin: 30px auto 0
  grid-column: 2 / 5
  background: #fff
  box-shadow: 0px 0px 22px rgba(81, 84, 90, 0.11)
  color: #030028
  font-weight: 500
  font-size: 16px
  line-height: 23px
  opacity: .6
  transition: .3s
  display: none
  &:hover
    box-shadow: 0px 0px 22px rgba(81, 84, 90, 0.3)
  @media screen and (max-width: 425px)
    display: flex

.goBackButton
  position: absolute
  top: 138px
  left: 0
  border: none
  background: rgba(255, 255, 255, 0.15)
  border-radius: 7px
  font-weight: 500
  font-size: 16px
  line-height: 23px
  @media screen and (max-width: 768px)
    top: 80px
    left: 0

.features
  width: 170px
  position: relative
  z-index: 3
  margin: 32px 0 0
  border: none
  background: rgba(255, 255, 255, 0.15)
  border-radius: 7px
  font-weight: 500
  font-size: 16px
  line-height: 23px
  @media screen and (max-width: 425px)
    align-self: flex-start