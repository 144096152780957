.section
  background: linear-gradient(107.33deg, #0094E7 20.64%, #31ACD5 90.51%)
  width: 100vw
  position: relative
  //height: 100vh

.dollar
  position: absolute
  top: 22%
  left: 7%
  animation: big-dollar-levitate 4s infinite ease-in-out
  animation-direction: alternate-reverse

@keyframes big-dollar-levitate
  0%
    top: 20%
    transform: rotate(18deg)
  10%
    transform: rotate(20deg)
  90%
    transform: rotate(-20deg)
  100%
    top: 25%
    transform: rotate(-18deg)

.plusSmall
  position: absolute
  top: 19%
  left: 12%
  animation: plusSmallRotate 3s infinite
  animation-direction: alternate-reverse
  @media screen and (max-width: 425px)
    top: 12%
    left: 20%

@keyframes plusSmallRotate
  0%
    transform: rotate(-10deg)
  50%
    transform: rotate(360deg)
  100%
    transform: rotate(0deg)

.chatLeft
  position: absolute
  bottom: 30%
  left: 8%
  animation: chatZoom 2s infinite
  @media screen and (max-width: 425px)
    width: 42px

@keyframes chatZoom
  0%
    transform: scale(1) rotate(-8deg)
  20%
    transform: scale(1.1) rotate(-8deg)
  40%
    transform: scale(1) rotate(-8deg)
  100%
    transform: scale(1) rotate(-8deg)

.heartTopTitle
  position: absolute
  top: 23%
  left: 37%
  animation: 1.2s heartBeat infinite
  @media screen and (max-width: 425px)
    display: none

@keyframes heartBeat
  0%
    transform: scale(1)
  10%
    transform: scale(1.1)
  20%
    transform: scale(1)
  30%
    transform: scale(1.1)
  40%
    transform: scale(1)
  100%
    transform: scale(1)

.arrow
  position: absolute
  bottom: 27%
  left: 48%
  animation: .6s arrow-levitate infinite ease-in-out
  animation-direction: alternate-reverse
  @media screen and (max-width: 1751px)
    display: none

@keyframes arrow-levitate
  0%
    left: 47%
    bottom: 27%
  100%
    left: 48%
    bottom: 26%

.plusBig
  position: absolute
  top: 16%
  right: 38.3%
  animation: 1.9s plus-big-rotate infinite
  @media screen and (max-width: 425px)
    display: none

@keyframes plus-big-rotate
  0%
    transform: rotate(0)
  5%
    transform: rotate(12deg)
  10%
    transform: rotate(-12deg)
  15%
    transform: rotate(12deg)
  20%
    transform: rotate(-12deg)
  25%
    transform: rotate(0)
  100%
    transform: rotate(0)

.dollarSmall
  width: 37px
  height: 54px
  object-fit: contain
  position: absolute
  top: 22.5%
  right: 12%
  animation: 2.5s dollar-small-zoom infinite ease-in-out
  animation-direction: alternate-reverse
  @media screen and (max-width: 425px)
    display: none

@keyframes dollar-small-zoom
  0%
    transform: rotate(9deg) scale(1)
  100%
    transform: rotate(9deg) scale(1.3)

.plusRight
  width: 25px
  height: 25px
  position: absolute
  top: 28%
  right: 10%
  animation: plus-right-rotate 1s infinite ease-in-out
  animation-direction: alternate-reverse
  @media screen and (max-width: 425px)
    display: none

@keyframes plus-right-rotate
  0%
    transform: rotate(7deg)
  100%
    transform: rotate(547deg)

.heartRight
  position: absolute
  top: 36%
  right: 3%
  animation: 1.9s heartBeat infinite
  @media screen and (max-width: 425px)
    top: 21.5%

.cart
  position: absolute
  bottom: 49%
  right: 6%
  animation: 2s cartLevitate infinite ease-in-out
  animation-direction: alternate-reverse
  @media screen and (max-width: 425px)
    right: 2%
    animation: 2s cartLevitateMob infinite ease-in-out
    animation-direction: alternate-reverse

@keyframes cartLevitate
  0%
    bottom: 49%
  100%
    bottom: 52%

@keyframes cartLevitateMob
  0%
    bottom: 38%
  100%
    bottom: 40%

.ruble
  position: absolute
  bottom: 26%
  right: 3%
  animation: 2.5s ruble-levitate infinite ease-in-out
  animation-direction: alternate-reverse
  @media screen and (max-width: 425px)
    animation: 2.5s ruble-levitate-mob infinite ease-in-out
    animation-direction: alternate-reverse


@keyframes ruble-levitate
  0%
    bottom: 29%
  100%
    bottom: 23%

@keyframes ruble-levitate-mob
  0%
    bottom: 10%
  100%
    bottom: 14%

.registerContainer
  position: relative
  z-index: 5
  width: 100%
  position: relative
  padding: 138px 0 0
  min-height: 500px
  display: flex
  flex-direction: column
  //justify-content: center
  align-items: center

.formTitle
  margin: 0 0 16px
  font-weight: bold
  font-size: 48px
  letter-spacing: 0.02em
  color: #fff
  &_login
    margin: 0 0 47px
  @media screen and (max-width: 768px)
    text-align: center
    font-size: 38px

.formSubTitle
  max-width: 525px
  margin: 0 0 47px
  font-weight: 500
  font-size: 20px
  line-height: 30px
  text-align: center
  color: #FFFFFF
  opacity: 0.8
  @media screen and (max-width: 768px)
    font-size: 16px
    margin: 0 0 22px

.registerFormContainer,
.loginFormContainer
  margin: 0 0 40px
  width: 85%
  padding: 45px 88px 40px
  background: rgba(255, 255, 255, 0.15)
  border-radius: 30px
  display: flex
  justify-content: space-between
  @media screen and (max-width: 990px)
    padding: 45px 40px 40px
  @media screen and (max-width: 768px)
    width: 100%
    padding: 27px 18px 23px
    flex-direction: column

.loginFormContainer
  padding: 45px 107px 34px
  flex-direction: column
  max-width: 680px
  @media screen and (max-width: 768px)
    width: 100%
    max-width: 100%
    padding: 27px 18px 23px
    flex-direction: column

.registerFormLeftCol,
.registerFormRightCol
  width: 49%
  display: flex
  flex-direction: column
  @media screen and (max-width: 768px)
    width: 100%

.registerFormRightCol

.countryAndCityContainer
  margin: 0 0 32px
  display: flex
  justify-content: space-between
  @media screen and (max-width: 768px)
    margin: 16px 0 14px

.passwords
  display: flex
  flex-direction: column

.checkboxes
  display: flex
  flex-direction: column
  align-items: flex-start
  @media screen and (max-width: 990px)
    flex-direction: column
    align-items: flex-start
.loginBtnContainer
  margin: 44px 0 16px

.forgetPasswordLink
  font-weight: 500
  font-size: 20px
  //line-height: 30px
  color: #fff
  opacity: 0.8
  a
    color: #fff
    text-decoration: none
    &:hover
      text-decoration: underline
  @media screen and (max-width: 425px)
    font-size: 16px