.section
  padding: 0 0 30px
  //height: 500px
  @media screen and (max-width: 768px)
    padding: 0 0 80px

.wrapper
  padding: 20px 0 0
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center
  @media screen and (max-width: 1025px)
    flex-direction: column
  @media screen and (max-width: 425px)
    padding: 0

.leftCol
  width: 738px
  img
    width: 100%
  @media screen and (max-width: 769px)
    width: 100%

.rightCol
  width: 430px
  @media screen and (max-width: 1441px)
    width: 520px
  @media screen and (max-width: 769px)
    width: 100%

.card
  display: flex
  margin: 0 0 18px

.numContainer
  margin: 0 20px 0 0
  @media screen and (max-width: 426px)
    margin: 0 18px 0 0

.text
  margin: 0
  font-weight: 500
  font-size: 16px
  line-height: 23px
  color: #030028
  opacity: 0.7
  @media screen and (max-width: 426px)
    font-size: 14px
    line-height: 17px
