.section

.wrapper
  display: grid
  grid-gap: 30px
  grid-template-columns: repeat(4, 1fr)
  @media screen and (max-width: 1210px)
    grid-template-columns: repeat(3, 1fr)
  @media screen and (max-width: 910px)
    grid-template-columns: repeat(2, 1fr)
  @media screen and (max-width: 595px)
    grid-template-columns: repeat(1, 1fr)