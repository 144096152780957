.section

.wrapper
  display: flex
  justify-content: space-between
  @media screen and (max-width: 1051px)
    flex-direction: column-reverse

.leftCol
  max-width: 702px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: flex-start
  @media screen and (max-width: 426px)
    width: 95%
    margin: 20px auto 70px
    align-items: center

.paragraphs
  margin: 0 0 80px
  @media screen and (max-width: 611px)
    margin: 0 auto 40px
  @media screen and (max-width: 426px)
    width: 95%
    text-align: center

.text
  margin: 0 0 38px
  font-weight: 500
  font-size: 16px
  line-height: 23px
  color: rgba(3, 0, 40, .7)
  strong
    color: rgb(3, 0, 40)
  &:last-child
    margin: 0
  @media screen and (max-width: 611px)
    margin: 0 0 20px
    font-size: 14px
    line-height: 17px
  //@media screen and (max-width: 426px)
  //  font-size: 14px
  //  line-height: 16px

.textBold

.rightCol
  position: relative
  width: 580px
  @media screen and (max-width: 611px)
    width: 95%
    margin: 0 auto
    img
      width: 100%
      @media screen and (max-width: 611px)
        margin: 0 0 20px

.bag
  position: absolute
  bottom: 16px
  right: 95px
  z-index: 2
  @media screen and (max-width: 1148px)
    bottom: 28px
  @media screen and (max-width: 1069px)
    bottom: 14px
  @media screen and (max-width: 1008px)
    bottom: 16px
  @media screen and (max-width: 611px)
    width: 90px!important
  @media screen and (max-width: 425px)
    width: 80px!important
    bottom: 12px
    right: 65px
  @media screen and (max-width: 375px)
    bottom: 10px

.dollarYellow
  position: absolute
  bottom: 50px
  right: 80px
  @media screen and (max-width: 1148px)
    bottom: 56px
  @media screen and (max-width: 1051px)
    bottom: 50px
  @media screen and (max-width: 1008px)
    bottom: 50px
  @media screen and (max-width: 611px)
    width: 40px!important
  @media screen and (max-width: 425px)
    width: 30px!important
    bottom: 24px
    right: 48px
  @media screen and (max-width: 375px)
    width: 28px!important
    bottom: 22px
    right: 44px
  @media screen and (max-width: 320px)
    width: 28px!important
    bottom: 16px
    right: 36px

.rubleYellow
  position: absolute
  bottom: 50px
  right: 74px
  @media screen and (max-width: 1148px)
    bottom: 56px
  @media screen and (max-width: 1119px)
    bottom: 90px
  @media screen and (max-width: 1051px)
    bottom: 50px
  @media screen and (max-width: 992px)
    bottom: 50px
  @media screen and (max-width: 611px)
    width: 40px!important
  @media screen and (max-width: 425px)
    width: 35px!important
    bottom: 30px
    right: 48px
  @media screen and (max-width: 375px)
    bottom: 24px
    right: 40px
  @media screen and (max-width: 350px)
    bottom: 24px
    right: 35px
  @media screen and (max-width: 350px)
    width: 30px!important
    bottom: 20px
    right: 35px

.rubleRed
  position: absolute
  top: 186px
  left: 102px
  @media screen and (max-width: 611px)
    width: 35px!important
    top: 38%
    left: 17%
  @media screen and (max-width: 599px)
    top: 36%
  @media screen and (max-width: 471px)
    width: 30px!important
    top: 35%
    left: 16.5%
  @media screen and (max-width: 425px)
    width: 25px!important
    top: 37%
    left: 17%
  @media screen and (max-width: 376px)
    width: 22px!important
    top: 35%
  @media screen and (max-width: 335px)
    top: 34%
    left: 16.5%

.dollarRed
  position: absolute
  top: 188px
  left: 105px
  @media screen and (max-width: 611px)
    width: 25px!important
    top: 36.5%
    left: 18%
  @media screen and (max-width: 461px)
    top: 35%
    left: 17%
  @media screen and (max-width: 376px)
    width: 22px!important
    top: 34.3%
  @media screen and (max-width: 335px)
    width: 18px!important
    top: 34%
    left: 17.1%