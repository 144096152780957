.number
  //margin: 50px 20px 0 0
  width: 75px
  display: flex
  justify-content: space-around
  align-items: flex-start
  font-weight: 700
  font-size: 120px
  line-height: 124px
  color: #78C2DD
  @media screen and (max-width: 426px)
    width: 58px
    font-size: 80px
    line-height: 80px