.form
  max-width: 700px
  display: flex
  justify-content: space-between
  align-items: center
  input
    padding: 0 0 0 29px
    height: 48px
    width: 454px
    box-shadow: 0px 3.42735px 51.4103px rgba(20, 0, 79, 0.04)
    border-radius: 10px
    border: none
    outline: none
    font-size: 18px
    line-height: 30px
    &::placeholder
      font-weight: normal
      opacity: .4
      color: #030028
  @media screen and (max-width: 1440px)
    width: 700px
  @media screen and (max-width: 1205px)
    width: 100%
  @media screen and (max-width: 768px)
    display: none
.buttonBox
  @media screen and (max-width: 769px)
    display: none