.section
  padding: 0 0 180px
  //height: 500px
  @media screen and (max-width: 768px)
    padding: 0 0 80px
  
.wrapper
  padding: 20px 0 0
  width: 100%
  display: flex
  justify-content: space-between
  align-items: center
  @media screen and (max-width: 1025px)
    flex-direction: column
  @media screen and (max-width: 425px)
    padding: 0

.leftCol
  width: 55%
  @media screen and (max-width: 1440px)
    width: 50%
  @media screen and (max-width: 1025px)
    width: 100%

.videoContainer
  width: 100%
  height: 444px
  overflow: hidden
  border-radius: 37px
  @media screen and (max-width: 1440px)
    height: 370px
  @media screen and (max-width: 1025px)
    height: 540px
  @media screen and (max-width: 768px)
    height: 410px
  @media screen and (max-width: 425px)
    height: 230px
    border-radius: 20px
  @media screen and (max-width: 375px)
    height: 200px
  @media screen and (max-width: 320px)
    height: 170px
  video[poster]
    object-fit: cover

.heading
  width: 100%
  display: flex
  align-items: center
  svg
    margin: 0 12px 0 0

.rightCol
  width: 43%
  height: 500px
  font-weight: 500
  font-size: 16px
  line-height: 23px
  color: rgba(3, 0, 40, .7)
  display: flex
  flex-direction: column
  justify-content: center
  span
    color: rgba(3, 0, 40, 1)
    font-weight: bold
  &_with_title
    width: 40%
  @media screen and (max-width: 1440px)
    width: 46%
    height: 100%
    //margin: 50px 0 0
  @media screen and (max-width: 1025px)
    width: 100%
  //@media screen and (max-width: 768px)
  //  font-size: 18px
  //  line-height: 25px
  @media screen and (max-width: 426px)
    margin: 20px 0 0

.text
  margin: 0 0 30px
  &:last-child
    margin: 0

.paragraph
  margin: 0
  &:last-child
    margin: 0
  @media screen and (max-width: 426px)
    //margin: 0 0 30px
    font-size: 14px
    line-height: 16px
    width: 95%
    text-align: center
    //&:last-child
    //  margin: 0

.boldText
  color: rgba(3, 0, 40, 1)
  font-weight: 700
