.section
  width: 100%
  margin: 100px 0 0
  background: linear-gradient(107.33deg, #0094E7 20.64%, #31ACD5 90.51%), #E8F5F9
  //height: 1000px
  position: relative
  overflow: hidden
  transition: .3s
  @media screen and (max-width: 1340px)
    padding: 0 0 50px
  @media screen and (max-width: 426px)
    padding: 0 0 100px
    margin: 0

.title
  width: 600px
  margin: 0 0 36px
  font-weight: 700
  font-size: 48px
  line-height: 50px
  color: #fff
  @media screen and (max-width: 768px)
    align-self: flex-start
  @media screen and (max-width: 540px)
    font-weight: 700
    font-size: 28px
    line-height: 30px
    width: 90%

.content
  margin: 260px 0 150px
  min-height: 650px
  display: flex
  flex-direction: column
  justify-content: center
  @media screen and (max-width: 1440px)
    min-height: 300px
  @media screen and (max-width: 1260px)
    margin: 800px 0 150px
  @media screen and (max-width: 768px)
    margin: 570px 0 0
    display: flex
    flex-direction: column
    align-items: center
  @media screen and (max-width: 510px)
    margin: 500px 0 0
  @media screen and (max-width: 426px)
    margin: 400px 0 0
    //transform: translateY(-30px)
  @media screen and (max-width: 321px)
    margin: 316px 0 0

.list
  position: relative
  z-index: 7
  @media screen and (max-width: 768px)
    align-self: flex-start

.listItem
  position: relative
  display: flex
  align-items: center
  margin: 0 0 18px
  @media screen and (max-width: 425px)
    margin: 0 0 16px
  p
    margin: 0 0 0 20px
    font-weight: 500
    font-size: 16px
    line-height: 23px
    color: #fff
    @media screen and (max-width: 425px)
      font-weight: 400
      font-size: 14px
      line-height: 17px

.info
  position: relative
  width: 25px
  height: 25px
  cursor: pointer
  svg
    transition: .2s
    &:hover
      opacity: .7

.tooltip
  padding: 20px
  color: #fff
  position: absolute
  //top: -460%
  bottom: 0
  left: 60px
  z-index: 5
  width: 350px
  font-weight: 300
  font-size: 14px
  line-height: 15px
  background: rgba(0, 0, 0, .95)
  backdrop-filter: blur(5px)
  border-radius: 15px
  visibility: hidden
  opacity: 0
  transition: .2s
  box-shadow: 0 0 15px 5px #0005
  &_active
    visibility: visible
    opacity: 1
    left: 40px
  @media screen and (max-width: 376px)
    bottom: 34px
    left: 0

.wave
  width: 100%
  height: 100px
  background-image: url('../../../images/wave-min.png')
  background-size: contain
  background-repeat: no-repeat
  background-color: transparent
  position: absolute
  top: 0
  left: 0

.waveTop
  position: absolute
  top: -291px
  left: calc( 50% - 1400px )
  width: 2778px
  object-fit: cover
  @media screen and (max-width: 1920px)
    left: calc( 50% - 1600px )
  @media screen and (max-width: 768px)
    top: -300px
    left: calc( 50% - 1800px )

.waveBottom
  position: absolute
  left: 0
  bottom: -360px
  z-index: 1
  bottom: -350px
  left: calc( 50% - 1500px )
  width: 3033px
  @media screen and (max-width: 1340px)
    left: calc( 50% - 1500px )

.macbook
  position: absolute
  right: 100px
  top: 250px
  @media screen and (max-width: 1815px)
    right: 0
    top: 280px
    width: 800px
  @media screen and (max-width: 1640px)
    width: 850px
  @media screen and (max-width: 1540px)
    width: 850px
  @media screen and (max-width: 1450px)
    width: 800px
  @media screen and (max-width: 1440px)
    width: 750px
  @media screen and (max-width: 1400px)
    width: 700px
  @media screen and (max-width: 1360px)
    width: 650px
  @media screen and (max-width: 1340px)
    display: none

.group
  width: 950px
  height: 607px
  position: absolute
  right: 100px
  top: 270px
  @media screen and (max-width: 1815px)
    right: 40px
    width: 800px
  @media screen and (max-width: 1640px)
    width: 850px
  @media screen and (max-width: 1540px)
    width: 850px
  @media screen and (max-width: 1450px)
    width: 800px
  @media screen and (max-width: 1440px)
    width: 750px
  @media screen and (max-width: 1400px)
    width: 700px
  @media screen and (max-width: 1360px)
    width: 650px
  @media screen and (max-width: 1340px)
    right: calc( 50% - 300px )
    //display: none
    //position: static
  @media screen and (max-width: 769px)
    top: 0
    right: 0
    width: 100%
  @media screen and (max-width: 426px)
    top: -70px
  @media screen and (max-width: 426px)
    top: -120px

.groupBg
  width: 100%
  height: 100%
  background: url("../../../images/features/group.png") no-repeat center
  background-size: contain
  position: relative
  z-index: 5

.cart
  position: absolute
  bottom: 120px
  right: -20px
  z-index: 6
  width: 54px
  height: 65px
  background: url("../../../images/features/cart.svg")
  @media screen and (max-width: 426px)
    display: none

.rubleLeftBottom
  position: absolute
  bottom: 320px
  left: 10px
  z-index: 1
  width: 63px
  height: 63px
  background: url("../../../images/features/ruble-63.svg") no-repeat center
  background-size: contain
  @media screen and (max-width: 426px)
    width: 25px
    height: 25px
    bottom: 320px
    left: 6px


.heart
  position: absolute
  top: 90px
  left: 30px
  z-index: 1
  width: 60px
  height: 60px
  background: url("../../../images/features/heart.svg") no-repeat center
  background-size: contain
  @media screen and (max-width: 1441px)
    top: 110px
  @media screen and (max-width: 426px)
    width: 24px
    height: 24px
    top: 220px
    left: 12px

.rubleLeftTop
  position: absolute
  top: -10px
  left: 84px
  width: 103px
  height: 103px
  z-index: 1
  background: url("../../../images/features/ruble-103.svg") no-repeat center
  background-size: contain
  @media screen and (max-width: 1441px)
    top: 10px
    left: 94px
  @media screen and (max-width: 426px)
    width: 40px
    height: 40px
    top: 164px
    left: 50px
  @media screen and (max-width: 376px)
    top: 176px
    left: 40px
    //width: 70px
    //height: 70px

.rubleTop
  position: absolute
  top: -10px
  left: 240px
  width: 48px
  height: 48px
  z-index: 1
  background: url("../../../images/features/ruble-44.svg") no-repeat center
  background-size: contain
  @media screen and (max-width: 1441px)
    top: 40px
    left: 260px
  @media screen and (max-width: 426px)
    top: 164px
    left: 110px
    width: 17px
    height: 17px
  @media screen and (max-width: 376px)
    top: 180px
    left: 100px
  @media screen and (max-width: 366px)
    display: none

.discount
  position: absolute
  top: -5px
  right: 90px
  width: 121px
  height: 137px
  background: url("../../../images/features/discount.svg") no-repeat center
  background-size: contain
  @media screen and (max-width: 1441px)
    top: 40px
    right: 70px
  @media screen and (max-width: 426px)
    top: 170px
    right: 50px
    width: 48px
    height: 54px
  @media screen and (max-width: 376px)
    top: 180px
    right: 48px

.rubleRight
  position: absolute
  top: 116px
  right: 40px
  width: 48px
  height: 48px
  background: url("../../../images/features/ruble-48.svg") no-repeat center
  background-size: contain
  @media screen and (max-width: 1441px)
    top: 164px
    right: 6px
  @media screen and (max-width: 426px)
    top: 228px
    right: 12px
    width: 19px
    height: 19px
  @media screen and (max-width: 376px)
    top: 216px
    right: 10px
    width: 30px
    height: 30px


.phone
  display: none
  //@media screen and (max-width: 1340px)
  //  display: block
  //  width: 197px
  //  align-self: center
  //@media screen and (max-width: 425px)
  //  margin: 0 0 16px