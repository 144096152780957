.section
  padding: 0 0 180px
  position: relative
  @media screen and (max-width: 425px)
    padding: 0

.infoContainer
  overflow: hidden
  position: relative
  padding: 43px
  width: 100%
  height: 449px
  box-shadow: 0px 4px 60px rgba(20, 0, 79, 0.08)
  border-radius: 37px
  display: flex
  justify-content: flex-start
  align-items: flex-start
  @media screen and (max-width: 1439px)
    width: 90%
    margin: 0 auto
  @media screen and (max-width: 1250px)
    padding: 0 4%
    height: 100%
    box-shadow: none
    display: grid
    grid-gap: 21px
    grid-template-columns: 1fr
    width: 100%

.blur
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: rgba(255, 255, 255, 0.75)
  backdrop-filter: blur(200px)
  z-index: -1
  @media screen and (max-width: 1250px)
    display: none

.card
  position: relative
  width: 26.5%
  height: 100%
  color: #030028
  margin: 0 110px 0 0
  @media screen and (max-width: 1250px)
    padding: 26px 20px 0
    min-height: 329px
    width: 100%
    border-radius: 30px
    background: rgba(255, 255, 255, 0.75)
    box-shadow: 0px 3.42735px 60px rgba(20, 0, 79, 0.08)
    backdrop-filter: blur(60px)
    overflow: hidden
  &_first
    @media screen and (max-width: 426px)
      min-height: 329px
  &_mid
    z-index: 2
    @media screen and (max-width: 426px)
      min-height: 363px
  &_last
    @media screen and (max-width: 426px)
      min-height: 431px
  //@media screen and (max-width: 426px)
  //  min-height: 390px

.cardTitle
  font-weight: 600
  font-size: 16px
  line-height: 23px
  white-space: nowrap
  display: flex
  flex-direction: column
  &_last
    @media screen and (max-width: 426px)
      display: flow-root
  @media screen and (max-width: 1250px)
    white-space: normal
    margin: 0 0 21px
  @media screen and (max-width: 426px)
    font-size: 14px
    line-height: 17px

.paragraphs
  position: relative
  z-index: 2
  @media screen and (max-width: 1250px)
    display: flex
    flex-direction: column
    align-items: flex-start

.cardText
  margin: 0 0 20px
  max-width: 90%
  padding: 10px 19px
  background: #fff
  border: 0.5px solid #78C2DD
  border-radius: 10px
  display: inline-block
  font-weight: 500
  font-size: 16px
  line-height: 18px
  @media screen and (max-width: 426px)
    max-width: 75%
    font-size: 14px
    line-height: 15px
.cardText_last
  //margin: 0 0 70px
  margin: 0

.cardImg
  position: absolute
  bottom: -43px
  z-index: 1
.girl
  right: -23%
  @media screen and (max-width: 1250px)
    right: 2%
    bottom: 0
    height: 90%
  @media screen and (max-width: 426px)
    height: 80%
.man
  right: -43%
  @media screen and (max-width: 1250px)
    right: -4%
  @media screen and (max-width: 426px)
    right: -15%
    bottom: 0
.woman
  right: -31%
  @media screen and (max-width: 1250px)
    right: 2%
  @media screen and (max-width: 425px)
    right: 0
    bottom: 0

.balloonBig
  position: absolute
  top: -50px
  left: 0
  z-index: -2
  width: 694px
  height: 1081px
  background-image: url('../../../images/need-online-shop/balloon-big.png')
  @media screen and (max-width: 1440px)
    left: -30%
  @media screen and (max-width: 426px)
    left: -75%

.balloonMob
  position: absolute
  bottom: 40px
  left: 0
  z-index: -2
  width: 209px
  height: 374px
  background-image: url('../../../images/need-online-shop/balloon-mob.png')
  display: none
  @media screen and (max-width: 426px)
    display: block

.balloonSmall
  position: absolute
  top: 120px
  left: 33%
  z-index: -2
  width: 215px
  height: 215px
  background-image: url('../../../images/need-online-shop/balloon-small.png')
  @media screen and (max-width: 1440px)
    left: 42%
  @media screen and (max-width: 426px)
    top: 50%
    left: 70%

.balloonMedium
  position: absolute
  top: 50px
  right: 0
  z-index: -2
  width: 674px
  height: 954px
  background-image: url('../../../images/need-online-shop/balloon-medium.png')
  @media screen and (max-width: 1440px)
    right: -10%
  @media screen and (max-width: 426px)
    display: none
    //top: 50%
    //right: -100%