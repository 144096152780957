.checkbox
  //margin: 6px 40px 0 0
  min-height: 24px
  position: relative
  display: flex
  justify-content: center
  align-items: center
.realCheckBox
  position: absolute
  top: 0
  left: -9999px
  

.visibleCheckBox
  width: 18px
  height: 18px
  background: rgba(255, 255, 255, 0.15)
  border-radius: 4px
  transition: .3s
  display: flex
  justify-content: center
  align-items: center
  cursor: pointer
  &:hover
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.12)
  div
    transition: .3s
    //transform: translateX(2px)
    width: 11px
    height: 8px
    background-image: url('../../../images/icon/arrow-checkbox.png')
    background-size: contain
    background-repeat: no-repeat
    visibility: hidden
    opacity: 0

.label
  cursor: pointer
  margin: 0 0 0 8px
  font-weight: normal
  font-size: 12px
  color: #fff
  @media screen and (max-width: 375px)
    font-size: 10px
  @media screen and (max-width: 355px)
    font-size: 8px
  
.realCheckBox:checked + .visibleCheckBox
  div
    visibility: visible
    opacity: 1