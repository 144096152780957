.wrapper
  position: relative
  width: 48.5%
  &::after
    content: url("../../../images/icon/arrow-select.png")
    width: 12px
    height: 6px
    position: absolute
    top: calc( 50% - 10px )
    right: 20px
    color: #030028
.input
  position: relative
  padding: 9px 29px
  width: 100%
  height: 48px
  background: transparent
  border: 2px solid #FFFFFF
  box-sizing: border-box
  filter: drop-shadow(0px 3.42735px 51.4103px rgba(20, 0, 79, 0.04))
  border-radius: 10px
  outline: none
  appearance: none
  font-weight: 500
  font-size: 18px
  color: #fff
  &::placeholder
    color: #fff

.citiesWrapper
  padding: 10px
  width: 100%
  height: 231px
  overflow-y: scroll
  overflow-x: hidden
  background: #fff
  box-shadow: 0px 3.42735px 51.4103px rgba(20, 0, 79, 0.04)
  border-radius: 10px
  position: absolute
  top: 52px
  left: 0
  z-index: 9
  visibility: hidden
  opacity: 0
  transition: .2s
  &_active
    visibility: visible
    opacity: 1
  &::-webkit-scrollbar
    width: 5px
  &::-webkit-scrollbar-thumb
    width: 5px
    background: #40AFDF
    border-radius: 10px

.listItem
  border-radius: 5px
  padding: 3px 0 3px 7px
  font-weight: 500
  font-size: 18px
  line-height: 22px
  color: #000
  cursor: pointer
  transition: .3s
  &:hover
    background: rgba(0, 0, 0, 0.1)