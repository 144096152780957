.section
  position: relative
  @media screen and (max-width: 425px)
    padding: 50px 0 0

.gridContainer
  display: grid
  grid-gap: 20px
  grid-template-columns: repeat(5, 1fr)
  @media screen and (max-width: 425px)
    display: none
    //grid-gap: 16px
    //width: 95%
    //margin: 0 auto 50px

.gridContainer_mob
  display: none
  @media screen and (max-width: 425px)
    display: grid
    grid-gap: 16px
    width: 95%
    margin: 0 auto 50px