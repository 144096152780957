.section
  margin: 128px 0 80px

.wrapper
  width: 100%
  margin: 128px 0 80px
  display: flex
  justify-content: space-between
  @media screen and (max-width: 1412px)
    flex-direction: column
    align-items: center
  @media screen and (max-width: 768px)
    flex-direction: column
    margin: 70px 0 20px

.leftCol
  position: relative
  z-index: 1
  width: 60%
  @media screen and (max-width: 1440px)
    display: flex
    flex-direction: column
    justify-content: center
  @media screen and (max-width: 768px)
    width: 100%

.rightCol
  position: relative
  z-index: 1
  height: 469px
  position: relative
  transform: translateX(170px)
  @media screen and (max-width: 1750px)
    width: 100%
    //margin: 50px auto 0
    margin: 0 auto
    transform: translateX(0) !important
  @media screen and (max-width: 1412px)
    width: 50%
    margin: 80px auto 0
  @media screen and (max-width: 1114px)
    width: 70%
  @media screen and (max-width: 930px)
    width: 85%
  @media screen and (max-width: 769px)
    display: flex
    flex-direction: column
    justify-content: flex-end

    height: 343px
    width: 90%
  @media screen and (max-width: 425px)
    margin: 10px auto 0

.title
  margin: 0 0 26px
  color: #ffffff
  font-size: 48px
  //line-height: 62px
  font-weight: 700
  span
    font-size: 50px
    font-weight: 800
  @media screen and (max-width: 1440px)
    font-size: 38px
    span
      font-size: 48px
  @media screen and (max-width: 768px)
    width: 90%
    display: flex
    flex-direction: column
    text-align: center
    font-size: 20px
    margin: 0 auto 14px
    //line-height: 110%
    span
      font-size: 55px
      margin: 0 0 7px
  @media screen and (max-width: 425px)
    width: 73%
  @media screen and (max-width: 375px)
    width: 80%
  @media screen and (max-width: 355px)
    width: 88%

.defis
  @media screen and (max-width: 768px)
    display: none

.printSymbols
  margin: 0 auto
  text-align: center
  font-size: 48px!important
  @media screen and (max-width: 425px)
    width: 80%

.subTitle
  width: 95%
  margin: 0 0 50px
  font-weight: 400
  font-size: 20px
  line-height: 30px
  opacity: .8
  color: #fff
  @media screen and (max-width: 1440px)
    font-size: 16px
    line-height: 18px
  @media screen and (max-width: 768px)
    display: none
    //font-weight: 500
    //font-size: 14px
    //line-height: 17px
    //text-align: center
    //margin: 0 auto
  &_mob
    font-weight: 500
    font-size: 14px
    line-height: 17px
    text-align: center
    color: #CCEAFA
    display: none
    span
      color: #fff
      font-weight: 800
    @media screen and (max-width: 768px)
      width: 90%
      display: block
      margin: 0 auto

.girlImg
  position: absolute
  bottom: 6px
  right: 20px
  z-index: 3
  @media screen and (max-width: 768px)
    width: 40%
  @media screen and (max-width: 425px)
    width: 55%

.bottomImg
  position: absolute
  bottom: 0
  right: 0
  @media screen and (max-width: 768px)
    width: 80%
  @media screen and (max-width: 425px)
    width: 100%


.leg
  position: absolute
  bottom: 30px
  right: 110px
  z-index: 2
  transition: .3s
  animation: 1s legTremor ease-in-out infinite
  animation-direction: alternate-reverse
  @media screen and (max-width: 768px)
    width: 35%
    right: 16%
    bottom: 8%
  @media screen and (max-width: 425px)
    width: 45%
    right: 29%
    bottom: 18px

@keyframes legTremor
  0%
    transform: rotate(0deg)
    transform-origin: right
  100%
    transform: rotate(-2deg) translateX(5px)
    transform-origin: right

.windowBig
  position: absolute
  top: 16px
  right: 300px
  @media screen and (max-width: 768px)
    top: 0
    right: 40%
    width: 130px
  @media screen and (max-width: 425px)
    top: 70px
    right: 47%
    width: 130px

.windowSmall
  position: absolute
  top: 16px
  right: 170px
  @media screen and (max-width: 768px)
    top: 0
    right: 27%
    width: 70px
  @media screen and (max-width: 425px)
    top: 70px
    right: 29%
    width: 55px
  @media screen and (max-width: 320px)
    right: 25%

.rate
  position: absolute
  top: 216px
  right: 315px
  @media screen and (max-width: 768px)
    display: none

.rate_mob
  height: 20px
  position: absolute
  //bottom: 158px
  //left: 10%
  display: none
  @media screen and (max-width: 768px)
    height: 30px
    display: block
    top: 32%
    left: 14%
  @media screen and (max-width: 425px)
    height: 20px
    top: 47%
    left: 10%

.rubleContainer
  position: absolute
  bottom: 18px
  right: 408px
  z-index: 1
  @media screen and (max-width: 768px)
    right: 57.5%
    bottom: 16px
    svg
      width: 40px
      height: 40px
  @media screen and (max-width: 595px)
    right: 57.5%
    bottom: 12px
  @media screen and (max-width: 595px)
    right: 57%
    bottom: 10px
    svg
      width: 35px
      height: 35px
  @media screen and (max-width: 455px)
    right: 57.5%
    bottom: 8px
    svg
      width: 28px
      height: 28px
  @media screen and (max-width: 425px)
    right: 73%
    bottom: 14px
  @media screen and (max-width: 376px)
    right: 73%
    bottom: 10px
  @media screen and (max-width: 320px)
    right: 71.5%
    bottom: 6px

.dollarContainer
  position: absolute
  bottom: 22px
  right: 420px
  z-index: 1
  @media screen and (max-width: 768px)
    right: 58.3%
    bottom: 12px
  @media screen and (max-width: 590px)
    right: 57.3%
    bottom: 12px
    svg
      width: 40px
      height: 40px
  @media screen and (max-width: 525px)
    right: 57.7%
    bottom: 10px
    svg
      width: 35px
      height: 35px
  @media screen and (max-width: 455px)
    right: 57.5%
    bottom: 8px
    svg
      width: 30px
      height: 30px
  @media screen and (max-width: 425px)
    right: 72.5%
    bottom: 12px
  @media screen and (max-width: 375px)
    right: 72%
    bottom: 10px
  @media screen and (max-width: 320px)
    right: 71%
    bottom: 6px

.buttonContainer
  display: none
  @media screen and (max-width: 769px)
    display: block
    margin: 40px 0 0