.container
  background: transparent
  height: 200px
  width: 100%
  position: relative
  bottom: -4px
  overflow: hidden
  @media screen and (max-width: 768px)
    height: 128px

.bigWave
  position: absolute
  left: 0
  bottom: 0
  height: 100%
  //width: 100%
  //animation: 5s infinite big-wave ease-in-out
  //animation-direction: alternate-reverse

.smallWave
  position: absolute
  right: 0
  bottom: 0
  height: 100%
  animation: 10s infinite small-wave ease-in-out
  animation-direction: alternate-reverse

@keyframes big-wave
  0%
    left: 0
  100%
    left: -170%

@keyframes small-wave
  0%
    right: 0
  100%
    right: -100%