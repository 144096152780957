.container
  position: relative
  margin: 0 0 16px
  display: flex
  //justify-content: space-between
  align-items: flex-start
  span
    padding: 22px 34px 22px 54px
    max-width: 620px
    background: #fff
    box-shadow: 0px 0px 22px rgba(81, 84, 90, 0.11)
    border-radius: 37px
    @media screen and (max-width: 425px)
      padding: 18px 18px 18px 48px

.arrow
  position: absolute
  top: 24px
  left: 20px
  width: 23px
  height: 24px
  background: url("../../images/advantage-server/arrow.png") no-repeat center
  @media screen and (max-width: 425px)
    background-size: contain
    top: 20px
    left: 28px
    width: 11px
    height: 11px

.text
  margin: 0
  font-weight: 500
  font-size: 20px
  line-height: 30px
  color: #030028
  @media screen and (max-width: 425px)
    font-size: 14px
    line-height: 17px
