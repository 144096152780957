.heading
  display: flex
  align-items: center
  @media screen and (max-width: 426px)
    justify-content: center

.number
  margin: 0 10px 0 0
  //width: 30px
  //height: 30px
  display: flex
  justify-content: center
  align-items: center

  font-weight: 700
  font-size: 120px
  line-height: 130%

  color: #78C2DD
  //color: #fff
  //background: #030028
  //border-radius: 50%

.title
  margin: 10px 0 16px
  //margin: 20px 0 16px
  font-weight: bold
  font-size: 24px
  line-height: 120%
  color: #030028
  @media screen and (max-width: 1401px)
    white-space: normal
  @media screen and (max-width: 1441px)
    font-size: 22px
  @media screen and (max-width: 426px)
    font-size: 20px
    line-height: 110%
    width: 100%
    //text-align: center
