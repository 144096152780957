.navigation
  &_pos_header
    @media screen and (max-width: 1400px)
      display: none

.list
  list-style-type: none
  display: flex
  justify-content: space-between
  align-items: center
  //@media screen and (max-width: 425px)
  @media screen and (max-width: 1266px)
    flex-direction: column

.listItem
  margin: 0 40px 0 0
  color: #fff
  font-weight: bold
  font-size: 17px
  line-height: 30px
  cursor: pointer
  transition: .2s
  &:last-child
    margin: 0
  &:hover
    color: rgba(255, 255, 255, 0.6)
  @media screen and (max-width: 1560px)
    margin: 0 20px 0 0
  @media screen and (max-width: 1266px)
    margin: 0 auto 10px
  //@media screen and (max-width: 425px)
  //  margin: 0 auto 10px