.cardLarge
  position: relative
  padding: 32px
  grid-column-start: 1
  grid-column-end: 4
  background: #fff
  box-shadow: 0px 4px 60px rgba(20, 0, 79, 0.08)
  border-radius: 37px
  display: flex
  justify-content: space-between
  @media screen and (max-width: 1441px)
    padding: 26px
  @media screen and (max-width: 1024px)
    padding: 30px 24px 30px 24px
  @media screen and (max-width: 769px)
    width: 100%
    grid-column: 1 / 6!important

.cardLarge_reverse
  flex-direction: row-reverse
  //@media screen and (max-width: 1024px)
  //  flex-direction: row

.imgContainer
  position: relative

.img
  width: 200px
  object-fit: contain
  object-position: top
  @media screen and (max-width: 1024px)
    display: none

.column
  max-width: 540px
  @media screen and (max-width: 768px)
    max-width: 100%

.numContainer
  margin: 0 22px 0 0
  @media screen and (max-width: 426px)
    margin: 0 10px 0 0
    display: flex
    flex-direction: column
    justify-content: center

.texts
  margin: 0
  display: flex

.paragraphs
  display: flex
  flex-direction: column

.text
  //margin: 0 0 0 46px
  //margin: 0 0 10px
  margin: 0
  font-weight: 500
  font-size: 16px
  line-height: 23px
  color: #030028
  opacity: 0.7
  @media screen and (max-width: 1441px)
    font-size: 15px!important
    width: 90%
  @media screen and (max-width: 769px)
    font-size: 14px!important
    line-height: 17px

.rocket
  position: absolute
  left: 33%
  animation: rocket-levitate 2s infinite ease-in-out
  animation-direction: alternate-reverse
  //@media screen and (max-width: 1440px)
  //  left: 14.8%
  //@media screen and (max-width: 1350px)
  //  left: 15.5%
  //@media screen and (max-width: 1241px)
  //  left: 16.4%
  @media screen and (max-width: 1024px)
    display: none

@keyframes rocket-levitate
  0%
    transform: translateY(-80px)
  100%
    transform: translateY(-100px)
