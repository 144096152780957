.card
  padding: 34px 31px 50px
  color: #030028
  background: #fff
  box-shadow: 0px 0px 22px rgba(81, 84, 90, 0.11)
  border-radius: 37px
  display: flex
  flex-direction: column
  justify-content: space-between
  transition: .3s ease-in-out
  &:hover
    transform: translateY(-50px)!important
    background: rgb(0, 148, 231)
    box-shadow: -100px 0px 120px 0px rgba(232, 245, 249, 0.3) inset
    .title, .goods, .text, .price
      color: #fff
    .title
      border-bottom: 0.5px solid #fff
@media screen and (max-width: 768px)
  .card:hover
    transform: translateY(0)!important
.top

.bottom

.title
  transition: .3s
  margin: 0 0 9px
  padding: 0 0 22px
  font-weight: bold
  font-size: 24px
  line-height: 120%
  text-align: center
  border-bottom: 0.5px solid rgba(3, 0, 40, 0.8)
  @media screen and (max-width: 425px)
    font-size: 20px

.goods
  transition: .5s
  margin: 0 0 9px
  text-align: center
  color: rgba(3, 0, 40, 0.8)
  font-weight: bold
  font-size: 16px
  line-height: 23px
  @media screen and (max-width: 425px)
    font-size: 14px
    line-height: 17px

.text
  transition: .6s
  margin: 0 0 20px
  text-align: center
  color: rgba(3, 0, 40, 0.7)
  font-weight: 500
  font-size: 16px
  line-height: 23px
  @media screen and (max-width: 425px)
    font-size: 14px
    line-height: 17px

.price
  transition: .7s
  text-align: center
  margin: 10px 0 20px
  font-weight: 500
  font-size: 22px
  //line-height: 28px
  color: #78C2DD
  span
    transition: .1s
    font-weight: 600
    font-size: 60px
  @media screen and (max-width: 425px)
    font-size: 18px
    font-weight: 600
    span
      font-size: 50px

.buttonContainer
  display: flex
  justify-content: center
  align-items: center